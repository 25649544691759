import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const ResDeviceData = ({ resDevdata }) => {
  return (
    <div className="grid grid-cols-3 justify-items-center">
      {resDevdata.map((devData, index) => (
        <div
          key={index}
          className="relative text-white w-72 m-2 text-center transform transition-transform duration-200 hover:scale-105 p-4 rounded-lg shadow-lg bg-gradient-1 bg-custom-gradient-2"
        >
          <div className="absolute top-2 left-2 w-8 h-8">
            <img
              src={devData.device_image}
              alt={devData.name}
              className="object-contain"
            />
          </div>
          <div>
            <h3 className="text-sm font-bold">{devData.name}</h3>
            <p className="mt-3 text-xs px-2">{devData.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResDeviceData;
