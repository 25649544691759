import smartPhone from "../../../Assets/smart_phone.png";
import tablet from "../../../Assets/tablet.png";
import smartTV from "../../../Assets/smart_TV.png";
import laptops from "../../../Assets/laptop.png";
import gamingConsoles from "../../../Assets/gaming_console.png";
import VRheadsets from "../../../Assets/VR_headset.png";
export const deviceData = [
  {
    name: "Smartphones",
    text: "StreamVibe is optimized for both Android and iOS smartphones. Download our app from the Google Play Store or the Apple App Store",

    device_image: smartPhone,
  },
  {
    name: "Tablet ",
    text: "StreamVibe is optimized for both Android and iOS smartphones. Download our app from the Google Play Store or the Apple App Store",
    device_image: tablet,
  },
  {
    name: "Smart TV",
    text: "StreamVibe is optimized for both Android and iOS smartphones. Download our app from the Google Play Store or the Apple App Store",
    device_image: smartTV,
  },
  {
    name: "Laptops",
    text: "StreamVibe is optimized for both Android and iOS smartphones. Download our app from the Google Play Store or the Apple App Store",
    device_image: laptops,
  },
  {
    name: "Gaming Consoles",
    text: "StreamVibe is optimized for both Android and iOS smartphones. Download our app from the Google Play Store or the Apple App Store",

    device_image: gamingConsoles,
  },
  {
    name: "VR Headsets ",
    text: "StreamVibe is optimized for both Android and iOS smartphones. Download our app from the Google Play Store or the Apple App Store",
    device_image: VRheadsets,
  },
];
