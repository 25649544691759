import React from 'react'
import Compare_Plan from "../Components/Subscription_Component/Compare_Plan";
import Navbar from '../Components/Home_Component/Navbar';
import Trial from '../Components/Home_Component/Trail';


const Subscription = () => {
  return (
    <div>
       <Navbar/>
       <Compare_Plan />
      
    </div>
  )
}

export default Subscription
