import React from "react";
import plans from "./PlanData";

const Plan = () => {
  return (
    <div className="bg-[#141414] text-white p-8 mt-5 m-20">
      <div className="text-justify mb-12 ">
        <h1 className="text-4xl font-bold">
          Choose the plan that’s right for you
        </h1>
        <p className="text-lg mt-4">
          Join StreamVibe and select from our flexible subscription options
          tailored to suit your viewing preferences. Get ready for non-stop
          entertainment!
        </p>
      </div>
      <div className="flex justify-end mb-8 ">
        <div className="bg-gray-800 text-gray-400 rounded-lg p-2 flex">
          <button className="px-3 py-2 bg-gray-800 rounded-l-lg focus:outline-none">
            Monthly
          </button>
          <button className="px-3 py-2 bg-gray-700 rounded-lg focus:outline-none ">
            Yearly
          </button>
        </div>
      </div>
      <div className="flex flex-wrap justify-between text-justify">
        {plans.map((plan, index) => (
          <div key={index} className="bg-[#1A1A1A] p-6 rounded-lg w-80 m-4">
            <h2 className="text-2xl font-bold mb-4">{plan.name}</h2>
            <p className="mb-6">{plan.description}</p>
            <div className="text-3xl font-bold mb-4">
              {plan.price}
              <span className="text-base font-normal">/month</span>
            </div>
            <div className="flex justify-around gap-2">
              <button className="bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-700">
                {plan.trial}
              </button>
              <button className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700">
                {plan.choose}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plan;
