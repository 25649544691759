import React from "react";
import { Link } from "react-router-dom";
import img from "../../Assets/Logo (4).png";
import search_Icon from "../../Assets/SearchIcon.png";
import bell_Icon from "../../Assets/Bell_Icon.png";
import sub_container from "../../Assets/Sub_Container.png";
import play_button from "../../Assets/Play_Button.png";

import Navbar from "./Navbar";

const Hero = () => {
  return (
    <div className="h-full w-full">
      <div
        className="relative bg-cover min-h-screen"
        style={{
          backgroundImage: `url(${sub_container})`,
          backgroundSize: "100% 100%", // Ensures the image covers the container
          backgroundPosition: "Top center", // Anchors the image to the bottom
          backgroundRepeat: "repeat", // Prevents
        }}
      >
        <nav className="flex justify-evenly items-center p-4 bg-opacity-500">
          <img src={img} alt="Logo" />
          <div className="flex gap-12 bg-black rounded items-center px-4">
            <Link to="/" className="text-gray-300 hover:text-white no-underline">
              Home
            </Link>
            <Link to="/movie&shows" className="text-gray-300 hover:text-white no-underline">
              Movies & Shows
            </Link>
            <Link to="/support" className="text-gray-300 hover:text-white no-underline">
              Support
            </Link>
            <Link to="/subscription" className="text-gray-300 hover:text-white no-underline">
              Subscription
            </Link>
          </div>
          <div className="flex gap-4 bg-black px-4">
            <img src={search_Icon} alt="Search Icon" className="h-6" />
            <img src={bell_Icon} alt="Bell Icon" className="h-6" />
          </div>
        </nav>
        {/* <Navbar/> */}
      </div>

      <div className=" items-center text-center text-white justify-end bg-[#141414]">
        <h1 className="text-4xl font-bold">The Best Streaming Experience</h1>
        <p className="text-sm mt-3">
          StreamVibe is the best streaming experience for watching your favorite
          movies and shows on demand, anytime, anywhere. With
          <br />
          StreamVibe, you can enjoy a wide variety of content, including the
          latest blockbusters, classic movies, popular TV shows, and more.
          <br />
          You can also create your own watchlists, so you can easily find the
          content you want to watch.
        </p>

        <div className="flex justify-center mt-5">
          <button className="flex items-center bg-red-600 text-white text-sm rounded-xl px-4 py-2 hover:bg-red-700">
            <img src={play_button} className="h-5 mr-2" alt="Play Button" />
            Start Watching Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;

