import React from 'react'
import img from "../../Assets/Logo (4).png";
import search_Icon from "../../Assets/SearchIcon.png";
import bell_Icon from "../../Assets/Bell_Icon.png";
import { Link } from 'react-router-dom';

const Navbar = () => {
  return(
    <div>
      <nav className="flex justify-between items-center p-4 bg-dark">
        <img src={img} alt="Logo" className="h-10" />
        <div className="flex gap-12 bg-black h-10 rounded items-center px-4">
          <Link to="/" className="text-gray-200 hover:text-white">
            Home
          </Link>
          <Link to="/movies-shows" className="text-gray-400 hover:text-white">
            Movies & Shows
          </Link>
          <Link to="/support" className="text-gray-400 hover:text-white">
            Support
          </Link>
          <Link to="/subscription" className="text-gray-400 hover:text-white">
            Subscription
          </Link>
        </div>
        <div className="flex gap-4 bg-black px-4">
          <img src={search_Icon} alt="Search Icon" className="h-6" />
          <img src={bell_Icon} alt="Bell Icon" className="h-6" />
        </div>
      </nav>
    </div>
  );
}

export default Navbar
