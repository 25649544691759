const plans = [
  {
    name: "Basic Plan",
    description:
      "Enjoy an extensive library of movies and shows, featuring a range of content, including recently released titles.",
    price: "$9.99",
    trial: "Start Free Trial",
    choose: "Choose Plan",
  },
  {
    name: "Standard Plan",
    description:
      "Access to a wider selection of movies and shows, including most new releases and exclusive content.",
    price: "$12.99",
    trial: "Start Free Trial",
    choose: "Choose Plan",
  },
  {
    name: "Premium Plan",
    description:
      "Access to the widest selection of movies and shows, including all new releases and Offline Viewing.",
    price: "$14.99",
    trial: "Start Free Trial",
    choose: "Choose Plan",
  },
];

export default plans;
