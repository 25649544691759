import React from "react";
import { CardData } from "./CardData";
import Categories from "./Categories";

const MoviesData = () => {
  return (
    <div className="m-20">
      <h2 className="text-white text-justify text-3xl font-bold">
        Explore our wide variety of categories
      </h2>
      <p className="text-gray-400 text-justify mb-8">
        Whether you're looking for a comedy to make you laugh, a drama to make
        you think, or a documentary to learn something new.
      </p>
      <Categories moviesData={CardData} />
    </div>
  );
};

export default MoviesData;
