import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Subscription from "./Pages/Subscription";
import Movies from "./Pages/Movies";
import Support from "./Pages/Support";
import Footer from "./Components/Home_Component/Footer";
import Trial from "./Components/Home_Component/Trail";


function App() {
  return (
    <div className="App bg-[#141414] ">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/movie&shows" element={<Movies />}></Route>
          <Route path="/Subscription" element={<Subscription />}></Route>
          <Route path="/Support" element={<Support />}></Route>
        </Routes>
        <Trial />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
