import React from "react";
import { deviceData } from "./DeviceData";
import ResDeviceData from "./ResDeviceData";

const ResData = () => {
  return (
    <div className="">
      <h2 className="text-white text-justify text-3xl font-bold m-20">
        We Provide you streaming experience across various devices.
      </h2>
      <p className="text-gray-400 text-justify m-20 w-2/3">
        With StreamVibe, you can enjoy your favorite movies and TV shows
        anytime, anywhere. Our platform is designed to be compatible with a wide
        range of devices, ensuring that you never miss a moment of
        entertainment.
      </p>
      <ResDeviceData resDevdata={deviceData} />
    </div>
  );
};

export default ResData;
