import React from 'react'
import Navbar from "../Components/Home_Component/Navbar";
import FAQ from "../Components/Home_Component/FAQ"
import SupportPage from '../Components/Support_Component/SupportPage';
const Support = () => {
  return (
    <div>
      <Navbar/>
      <SupportPage />
      <FAQ />
    </div>
  )
}

export default Support
