import action_movies_container from "../../../Assets/action.png";
import adventure_movies_container from "../../../Assets/adventure.png";
import comedy_movies_container from "../../../Assets/comedy.png";
import drama_movies_container from "../../../Assets/drama.png";
import horror_movies_container from "../../../Assets/horror.png";

export const CardData = [
  {
    id: 1,
    name: "Action Movies",
    image: action_movies_container,
    category: "Action",
  },
  {
    id: 2,
    name: "Adventure Movies",
    image: adventure_movies_container,
    category: "Adventure",
  },
  {
    id: 3,
    name: "Comedy Movies",
    image: comedy_movies_container,
    category: "Comedy",
  },
  {
    id: 4,
    name: "Drama Movies",
    image: drama_movies_container,
    category: "Drama",
  },
  {
    id: 5,
    name: "Horror Movies",
    image: horror_movies_container,
    category: "Horror",
  },
  {
    id: 1,
    name: "Action Movies",
    image: action_movies_container,
    category: "Action",
  },
  {
    id: 2,
    name: "Adventure Movies",
    image: adventure_movies_container,
    category: "Adventure",
  },
  {
    id: 3,
    name: "Comedy Movies",
    image: comedy_movies_container,
    category: "Comedy",
  },
  {
    id: 4,
    name: "Drama Movies",
    image: drama_movies_container,
    category: "Drama",
  },
  {
    id: 5,
    name: "Horror Movies",
    image: horror_movies_container,
    category: "Horror",
  },
  {
    id: 1,
    name: "Action Movies",
    image: action_movies_container,
    category: "Action",
  },
  {
    id: 2,
    name: "Adventure Movies",
    image: adventure_movies_container,
    category: "Adventure",
  },
  {
    id: 3,
    name: "Comedy Movies",
    image: comedy_movies_container,
    category: "Comedy",
  },
  {
    id: 4,
    name: "Drama Movies",
    image: drama_movies_container,
    category: "Drama",
  },
  {
    id: 5,
    name: "Horror Movies",
    image: horror_movies_container,
    category: "Horror",
  },
];
