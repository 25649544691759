import React from "react";
import Hero from "../Components/Home_Component/Hero";
import MoviesData from "../Components/Home_Component/Categories/MoviesData";
import Plan from "../Components/Home_Component/PLAN/Plan";
import ResData from "../Components/Home_Component/Devices/ResData";
import Faq from "../Components/Home_Component/FAQ";


const Home = () => {
  return (
    <div className="">
      <Hero />
      <MoviesData />
      <ResData />
      <Faq />
      <Plan />
    </div>
  );
};

export default Home;
