
import React from "react";
import avengers from "../../Assets/Avengers.png";
import plusButton from "../../Assets/PlusButton.png";
import ThumbButton from "../../Assets/ThumbButton.png";
import MusicButton from "../../Assets/MusicButton.png";

const Avengers = () => {
  return (
    <div className="relative bg-black h-screen text-white flex items-center justify-center">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${avengers})`,
        }}
      >
        <div className="relative text-center p-6 bg-opacity-50 mt-96">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Avengers: Endgame
          </h1>
          <p className=" text-lg text-sm mb-6">
            With the help of remaining allies, the Avengers must assemble once
            more in order to undo Thanos's actions and restore order to the<br></br>
            universe, no matter what the consequences may be in store, and no
            matter who they face... Avengers assemble.
          </p>
          <div className=" absulate  flex justify-center space-x-4">
            <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full flex items-center">
              <svg
                className="w-6 h-6 mr-2"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M10 8v8l6-4z" />
              </svg>
              Play Now
           </button>
            <img src={plusButton}></img>
            <img src={ThumbButton}></img>
            <img src={MusicButton}></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Avengers;



