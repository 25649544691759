import React from "react";
// import Trial_bg_Image from "../../Assets/Trial_bg_Image";

const Trial = () => {
  return (
  
    <div
      className="relative bg-cover  m-20"
      // style={{ backgroundImage: `url(${Trial_bg_Image})` }}
    >
      <div className="flex justify-evenly items-center">
        <div className="bg-opacity-50 h-64 flex flex-col text-justify p-20">
          <h2 className="text-3xl font-bold text-white mb-4">
            Start your free trial today!
          </h2>
          <p className="text-white mb-6">
            This is a clear and concise call to action that encourages users to
            sign up for a free trial of StreamVibe.
          </p>
        </div>
        <button className="bg-red-600 h-16 w-44 text-white rounded-md text-lg font-medium hover:bg-red-700">
          Start a Free Trial
        </button>
      </div>
    </div>
  );
};

export default Trial;

