import React from 'react'
import Navbar from "../Components/Home_Component/Navbar";
import Avengers from '../Components/Movies_Component/Avengers';
import MoviesData from '../Components/Home_Component/Categories/MoviesData';
import PopulerTop10 from '../Components/Movies_Component/PopulerTop10';
import TrendingNow from '../Components/Movies_Component/TrendingNow';


const Movies = () => {
  return (
    <div>
      <Navbar />
      <Avengers/>
      <MoviesData/>
      <PopulerTop10 />
      <TrendingNow />
    </div>
  )
}

export default Movies
