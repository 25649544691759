import React from 'react';
import Facebook_Icon from "../../Assets/Facebook_Icon.png";
 import Linkedin_Icon from "../../Assets/Linkedin_Icon.png";
import Twitter_Icon from "../../Assets/Twitter_Icon.png";

const Footer = () => {
  return (
    <div className="bg-[#0F0F0F] text-gray-400">
      <div className="flex gap-20 justify-evenly text-justify py-8">
        <div>
          <h2 className="font-medium text-white">Home</h2>
          <p>Categories</p>
          <p>Devices</p>
          <p>Pricing</p>
          <p>FAQ</p>
        </div>
        <div>
          <h2 className="font-medium text-white">Movies</h2>
          <p>Genres</p>
          <p>Trending</p>
          <p>New Release</p>
          <p>Popular</p>
        </div>
        <div>
          <h2 className="font-medium text-white">Shows</h2>
          <p>Genres</p>
          <p>Trending</p>
          <p>New Release</p>
          <p>Popular</p>
        </div>
        <div>
          <h2 className="font-medium text-white">Support</h2>
          <p>Contact Us</p>
        </div>
        <div>
          <h2 className="font-medium text-white">Subscription</h2>
          <p>Plans</p>
          <p>Features</p>
        </div>
        <div className="flex flex-col items-center">
          <h2 className="font-medium text-white">Contact With Us</h2>
          <div className="flex gap-4 mt-2">
            <img src={Facebook_Icon} alt="Facebook Icon" className="w-8 h-8" />
            <img src={Twitter_Icon} alt="Twitter Icon" className="w-8 h-8" />
            <img src={Linkedin_Icon} alt="LinkedIn Icon" className="w-8 h-8" />
          </div>
        </div>
      </div>
      <div className="flex justify-around items-center py-3 border-t border-gray-700">
        <p className="hover:text-white">
          &copy; 2023 Streamvib, All Rights Reserved
        </p>
        <div className="flex gap-4">
          <button className="hover:text-white">Terms of Use</button>
          <button className="hover:text-white">Privacy Policy</button>
          <button className="hover:text-white">Cookie Policy</button>
        </div>
      </div>
    </div>
  );
}

export default Footer;



