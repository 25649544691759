import React, { useEffect, useRef } from "react";
import { Container, Card, CardImg, CardBody, CardTitle } from "react-bootstrap";
import right_arrow from "../../../Assets/right_arrow.png";
import left_arrow from "../../../Assets/left_arrow.png";
import { CardData } from "../../Home_Component/Categories/CardData"; // Make sure this path is correct

const Categories = () => {
  const cardsRef = useRef(null);

  const scrollCards = (amount) => {
    if (cardsRef.current) {
      cardsRef.current.scrollLeft += amount;
    }
  };

  const handleWheel = (event) => {
    event.preventDefault();
    if (cardsRef.current) {
      cardsRef.current.scrollLeft += event.deltaY;
    }
  };

  useEffect(() => {
    const currentCardsRef = cardsRef.current;
    if (currentCardsRef) {
      currentCardsRef.addEventListener("wheel", handleWheel);
      return () => {
        currentCardsRef.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  return (
    <Container fluid className="m-20">
      <div className="flex justify-between text-center items-center mb-4">
        <h2 className="text-white text-3xl font-bold mb-4 text-justify">
          Popular Top 10 In Genres
        </h2>

        <div className="flex">
          <span onClick={() => scrollCards(-200)} className="cursor-pointer">
            <img src={left_arrow} alt="LeftArrow" className="w-6 h-6" />
          </span>
          <span
            onClick={() => scrollCards(+200)}
            className="cursor-pointer ml-2"
          >
            <img src={right_arrow} alt="RightArrow" className="w-6 h-6" />
          </span>
        </div>
      </div>
      <div
        className="flex overflow-x-auto whitespace-nowrap"
        ref={cardsRef}
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {CardData.map((data) => (
          <Card
            key={data.id}
            className="bg-gray-800 text-white mx-2"
            style={{ flex: "0 0 20%", minWidth: "20%" }} // Ensuring the cards have a fixed width
          >
            <CardImg
              className="transform transition-transform duration-200 hover:scale-105"
              variant="top"
              src={data.image}
              alt={data.category}
            />
            <CardBody className="p-2 bg-dark text-justify">
              <CardTitle className="transform transition-transform duration-200 hover:scale-105">
                {data.category}
              </CardTitle>
            </CardBody>
          </Card>
        ))}
      </div>
    </Container>
  );
};

export default Categories;

