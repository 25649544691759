import React from "react";
import Plan from "../Home_Component/PLAN/Plan";

const Compare_Plan = () => {
  return (
   <>
   <Plan />
    <div className="bg-[#141414] p-8 text-justify m-20">
     
      <h1 className="text-white text-3xl mb-4 ">
        Compare our plans and find the right one for you
      </h1>
      <p className="text-gray-300 mb-8">
        StreamVibe offers three different plans to fit your needs: Basic,
        Standard, and Premium. Compare the features of each plan and choose the
        one that's right for <br></br>you.
      </p>

      <div className="overflow-x-auto y-10">
        <table className="table-auto w-full border-collapse border-t border-gray-500 text-justify justify-center">
          <thead className="bg-[#141414] text-white">
            <tr>
              <th className="border-1 border-white p-3">Features</th>
              <th className="border-1 border-white p-3">Basic</th>
              <th className="border-1 border-white p-3">Standard</th>
              <th className="border-1 border-white p-3">Premium</th>
            </tr>
          </thead>
          <tbody className="bg-[#141414] text-gray-300 ">
            <tr>
              <td className="border-1 border-gray-500 p-3">Price</td>
              <td className="border-1 border-gray-500 p-3">$8.99/month</td>
              <td className="border-1 border-gray-500 p-3">$13.99/month</td>
              <td className="border-1 border-gray-500 p-3">$17.99/month</td>
            </tr>
            <tr>
              <td className="border-1 border-gray-500 p-3">Content</td>
              <td className="border-1 border-gray-500 p-3">
                Access to a wide selection of movies and shows, including some
                new releases.
              </td>
              <td className="border-1 border-gray-500 p-3">
                Access to a wider selection of movies and shows, including most
                new releases and exclusive content
              </td>
              <td className="border-1 border-gray-500 p-3">
                Access to a widest selection of movies and shows, including all
                new releases and Offline Viewing
              </td>
            </tr>
            <tr>
              <td className="border-1 border-gray-500 p-3">Devices</td>
              <td className="border-1 border-gray-500 p-3">
                Watch on one device simultaneously
              </td>
              <td className="border-1 border-gray-500 p-3">
                Watch on Two device simultaneously
              </td>
              <td className="border-1 border-gray-500 p-3">
                Watch on Four device simultaneously
              </td>
            </tr>
            <tr>
              <td className="border-1 border-gray-500 p-3">Free Trail</td>
              <td className="border-1 border-gray-500 p-3">7 Days</td>
              <td className="border-1 border-gray-500 p-3">7 Days</td>
              <td className="border-1 border-gray-500 p-3">7 Days</td>
            </tr>
            <tr>
              <td className="border-1 border-gray-500 p-3">Cancel Anytime</td>
              <td className="border-1 border-gray-500 p-3">Yes</td>
              <td className="border-1 border-gray-500 p-3">Yes</td>
              <td className="border-1 border-gray-500 p-3">Yes</td>
            </tr>
            <tr>
              <td className="border-1 border-gray-500 p-3">HDR</td>
              <td className="border-1 border-gray-500 p-3">No</td>
              <td className="border-1 border-gray-500 p-3">Yes</td>
              <td className="border-1 border-gray-500 p-3">Yes</td>
            </tr>
            <tr>
              <td className="border-1 border-gray-500 p-3">Dolby Atmos</td>
              <td className="border-1 border-gray-500 p-3">No</td>
              <td className="border-1 border-gray-500 p-3">Yes</td>
              <td className="border-1 border-gray-500 p-3">Yes</td>
            </tr>
            <tr>
              <td className="border-1 border-gray-500 p-3">Ad - Free</td>
              <td className="border-1 border-gray-500 p-3">No</td>
              <td className="border-1 border-gray-500 p-3">Yes</td>
              <td className="border-1 border-gray-500 p-3">Yes</td>
            </tr>
            <tr>
              <td className="border-1 border-gray-500 p-3">Offline Viewing</td>
              <td className="border-1 border-gray-500 p-3">No</td>
              <td className="border-1 border-gray-500 p-3">
                Yes, for select titles.
              </td>
              <td className="border-1 border-gray-500 p-3">
                Yes, for all titles.
              </td>
            </tr>
            <tr>
              <td className="border-1 border-gray-500 p-3">Family Sharing</td>
              <td className="border-1 border-gray-500 p-3">No</td>
              <td className="border-1 border-gray-500 p-3">
                Yes, up to 5 family members.
              </td>
              <td className="border-1 border-gray-500 p-3">
                Yes, up to 6 family members.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
};

export default Compare_Plan;
