import React, { useState } from "react";
import supportPageImage from "../../Assets/SupportPage.png";
import flagImage from "../../Assets/Flag.png";
import DropDownIcon from "../../Assets/DropDownIcon.png";

const SupportPage = () => {
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const countries = [
    { code: "IN", name: "India" },
    { code: "US", name: "United States" },
    { code: "UK", name: "United Kingdom" },
    // Add more countries as needed
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCountrySelect = (countryCode) => {
    setSelectedCountry(countryCode);
    setDropdownOpen(false);
  };

  return (
    <div className="bg-[#141414] text-white flex items-center justify-center p-8">
      <div className="flex flex-col md:flex-row w-full max-w-6xl gap-16">
        {/* Left Section */}
        <div className="md:w-1/2 p-4 flex items-center justify-center">
          <img
            src={supportPageImage}
            alt="Support Page"
            className="rounded-lg shadow-lg  h-auto"
          />
        </div>

        {/* Right Section */}
        <div className="md:w-1/2 bg-black p-16 rounded-lg shadow-lg flex flex-col text-justify">
          <form className="space-y-12 flex-grow">
            <div className="flex space-x-4">
              <div className="flex flex-col w-1/2">
                <label className="mb-2">First Name</label>
                <input
                  type="text"
                  placeholder="Enter First Name"
                  className="p-3 bg-[#141414] text-white rounded-lg focus:outline-none"
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label className="mb-2">Last Name</label>
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  className="p-3 bg-[#141414] text-white rounded-lg focus:outline-none"
                />
              </div>
            </div>

            {/* Email and Phone Number Section */}
            <div className="flex space-x-4">
              <div className="flex flex-col w-1/2">
                <label className="mb-2">Email</label>
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  className="p-3 bg-[#141414] text-white rounded-lg focus:outline-none"
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label className="mb-2">Phone Number</label>
                <div className="flex items-center bg-[#141414] rounded-lg p-2">
                  <div className="flex items-center relative">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={toggleDropdown}
                    >
                      <img src={flagImage} alt="Flag" className="w-6 h-4" />
                      <img
                        src={DropDownIcon}
                        alt="Dropdown"
                        className="w-3 h-3 ml-2"
                      />
                    </div>
                    {dropdownOpen && (
                      <ul className="absolute left-0 mt-2 bg-[#141414] rounded-lg shadow-lg z-10">
                        {countries.map((country) => (
                          <li
                            key={country.code}
                            className="p-2 hover:bg-gray-700 cursor-pointer"
                            onClick={() => handleCountrySelect(country.code)}
                          >
                            {country.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="flex-1">
                    <input
                      type="tel"
                      placeholder="Number"
                      className="p-2 bg-[#141414] text-white rounded-lg focus:outline-none w-full"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <label className="mb-2">Message</label>
              <textarea
                placeholder="Enter your Message"
                className="p-2 bg-[#141414] text-white rounded-lg focus:outline-none h-32 resize-none"
              ></textarea>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input type="checkbox" className="mr-2 h-4 w-4 text-red-600" />
                <label className="text-gray-300 text-sm">
                  I agree with Terms of Use and Privacy Policy
                </label>
              </div>
              <button
                type="submit"
                className="bg-red-600 text-white p-2 rounded-lg hover:bg-red-700 transition-colors"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;



