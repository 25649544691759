import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Faq = () => {
  // State to manage the visibility of answers
  const [visible, setVisible] = useState({});

  // Function to toggle the visibility of a specific answer
  const toggleVisibility = (index) => {
    setVisible((prevVisible) => ({
      ...prevVisible,
      [index]: !prevVisible[index],
    }));
  };

  // List of FAQs
  const faqs = [
    {
      question: "What is StreamVibe?",
      answer:
        "StreamVibe is a content streaming platform offering a variety of movies, TV shows, and more.",
    },
    {
      question: "How much does StreamVibe cost?",
      answer:
        "StreamVibe offers various subscription plans starting at $9.99 per month.",
    },
    {
      question: "What content is available on StreamVibe?",
      answer:
        "StreamVibe features a wide range of content including movies, TV shows, documentaries, and exclusive originals.",
    },
    {
      question: "How can I watch StreamVibe?",
      answer:
        "You can watch StreamVibe on any device with an internet connection, including smartphones, tablets, smart TVs, and computers.",
    },
    {
      question: "How do I sign up for StreamVibe?",
      answer:
        "Sign up for StreamVibe on our website by providing your email and creating a password.",
    },
    {
      question: "What is the StreamVibe free trial?",
      answer:
        "StreamVibe offers a 30-day free trial for new users to explore our content.",
    },
    {
      question: "How do I contact StreamVibe customer support?",
      answer:
        "Contact StreamVibe customer support through our website or by calling our support hotline.",
    },
    {
      question: "What are the StreamVibe payment methods?",
      answer:
        "StreamVibe accepts various payment methods including credit cards, PayPal, and gift cards.",
    },
  ];

  return (
    <Container className="text-white py-5 text-justify">
      <div className="text-center mb-5 flex justify-between text-justify">
        <div>
          <h1 className="text-4xl font-bold mb-3 text-justify">
            Frequently Asked Questions
          </h1>
          <p>
            Got questions? We've got answers! Check out our FAQ section to find
            answers to the most common questions about StreamVibe.
          </p>
        </div>
        <div>
          <Button variant="danger" className="bg-custom-black mt-3">
            Ask a Question
          </Button>
        </div>
      </div>
      <Row>
        {faqs.map((faq, index) => (
          <Col md={6} key={index}>
            <div>
              <section className="flex items-center justify-between bg-custom-black p-4 rounded-lg mb-0">
                <div className="flex items-center space-x-3">
                  <span className="bg-custom-black2 text-white p-2 rounded">
                    {String(index + 1).padStart(2, "0")}
                  </span>
                  <h5 className="text-lg font-semibold">{faq.question}</h5>
                </div>
                <Button
                  variant="outline-light"
                  onClick={() => toggleVisibility(index)}
                >
                  {visible[index] ? "-" : "+"}
                </Button>
              </section>
              {visible[index] && (
                <div className="bg-custom-black p-4 rounded-lg mt-2">
                  <p>{faq.answer}</p>
                </div>
              )}
              <hr className="bg-custom-red border-0 h-1 m-0" />
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Faq;
